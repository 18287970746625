import React, { Component } from "react";
import Layout from "./Layout";

export default class FAQ extends Component {
  render() {
    return (
      <Layout>
        <section className="main-content pt-nav">
          <div className="container">
            <div className="game-cat-section ">
              <div className="card box-content-main ">
                <div className="card-body ">
                  <div className="container pl-2 pr-2">
                    <h2 className="title">Frequently Asked Questions</h2>
                    <div className="accordion faq-box" id="accordionExample">
                      <div className="card">
                        <div className="card-header" id="headingOne">
                          <h5
                            className="mb-0 btn-header "
                            data-toggle="collapse"
                            data-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            <i className="fa fa-plus" aria-hidden="true"></i>
                            What is HTML5 Games by XOX MOBILE?
                          </h5>
                        </div>

                        <div
                          id="collapseOne"
                          className="collapse show"
                          aria-labelledby="headingOne"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            HTML5 is one stop portal that is dedicated
                            especially for XOX MOBILE Subscriber.
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="headingTwo">
                          <h5
                            className="mb-0 btn-header collapsed"
                            data-toggle="collapse"
                            data-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          >
                            <i className="fa fa-plus" aria-hidden="true"></i>How
                            do I play the games?
                          </h5>
                        </div>
                        <div
                          id="collapseTwo"
                          className="collapse"
                          aria-labelledby="headingTwo"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            User need to be subscribed to the portal first
                            before they are able to play to the games.
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="headingThree">
                          <h5
                            className="mb-0 btn-header collapsed"
                            data-toggle="collapse"
                            data-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            <i className="fa fa-plus" aria-hidden="true"></i>
                            What are the phones compatible to play the games?
                          </h5>
                        </div>
                        <div
                          id="collapseThree"
                          className="collapse"
                          aria-labelledby="headingThree"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            It can be play on any mobile device with minimum OS
                            such as iOS devices version 3.0+ or Android devices
                            version 2.0+
                          </div>
                        </div>
                      </div>

                      <div className="card">
                        <div className="card-header" id="heading4">
                          <h5
                            className="mb-0 btn-header collapsed"
                            data-toggle="collapse"
                            data-target="#collapse4"
                            aria-expanded="false"
                            aria-controls="collapse4"
                          >
                            <i className="fa fa-plus" aria-hidden="true"></i>{" "}
                            How many games I can play?
                          </h5>
                        </div>
                        <div
                          id="collapse4"
                          className="collapse"
                          aria-labelledby="heading4"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            You may play as many games as there is available in
                            the portal.
                          </div>
                        </div>
                      </div>

                      <div className="card">
                        <div className="card-header" id="heading5">
                          <h5
                            className="mb-0  btn-header collapsed"
                            data-toggle="collapse"
                            data-target="#collapse5"
                            aria-expanded="false"
                            aria-controls="collapse5"
                          >
                            <i className="fa fa-plus" aria-hidden="true"></i>
                            Is the data usage charges?
                          </h5>
                        </div>
                        <div
                          id="collapse5"
                          className="collapse"
                          aria-labelledby="heading5"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            Yes, data will be charged based on usage.
                          </div>
                        </div>
                      </div>

                      <div className="card">
                        <div className="card-header" id="heading6">
                          <h5
                            className="mb-0  btn-header collapsed"
                            data-toggle="collapse"
                            data-target="#collapse6"
                            aria-expanded="false"
                            aria-controls="collapse6"
                          >
                            <i className="fa fa-plus" aria-hidden="true"></i>
                            What are the sizes for each game?
                          </h5>
                        </div>
                        <div
                          id="collapse6"
                          className="collapse"
                          aria-labelledby="heading6"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            Varies from 2MB to 10MB.
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="heading7">
                          <h5
                            className="mb-0 btn-header   collapsed"
                            data-toggle="collapse"
                            data-target="#collapse7"
                            aria-expanded="false"
                            aria-controls="collapse7"
                          >
                            <i className="fa fa-plus" aria-hidden="true"></i>
                            How do I terminate the subscription?
                          </h5>
                        </div>
                        <div
                          id="collapse7"
                          className="collapse"
                          aria-labelledby="heading7"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            You need to send STOP FUN to 20000.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
