import React, { Component } from "react";
import Layout from "./Layout";
import { gamesApi } from "../api/apiUrl";
import { Redirect } from "react-router-dom";
import Header from "./Header";

export default class Search extends Component {
  state = { games: [] };

  gameList() {
    return this.props.location.state.games.map(game => {
      return (
        <div key={game.GameID} className="col-4 col-md-2 pl-0 pr-1">
          <div className="card">
            <a href={"/gameDetails/" + game.GameID}>
              <img className="card-img-top" src={game.GameLogo} alt="Game 1" />
            </a>
            <div className="card-body">
              <p className="game-title">
                <a href={"/gameDetails/" + game.GameID}>{game.GameTitle}r</a>
              </p>
            </div>
            <div className="card-footer">
              <a className="game-cat">{game.CategoryName}</a>
            </div>
          </div>
        </div>
      );
    });
  }
  render() {
    if (this.props.location.state.games.length < 1) {
      return (
        <Layout>
          <section className="main-content pt-nav">
            <div className="container">
              <div className="game-cat-section">
                <div className="container   pl-2 pr-2">
                  <div className="d-flex flex-column pb-2">
                    <div className="section-header up-margin">
                      <center>No games found. Please search again.</center>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Layout>
      );
    }
    return (
      <Layout>
        <section className="main-content pt-nav">
          <div className="container">
            <div className="game-cat-section">
              <div className="container   pl-2 pr-2">
                <div className="d-flex flex-column pb-2">
                  <div className="section-header up-margin">
                    {this.state.games.CategoryName}
                  </div>
                  {/* <div className="sub-header">
                    <span>Showing 12 from 96 Games</span>
                  </div> */}
                </div>
                <div className="game-group row m-0">{this.gameList()}</div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
