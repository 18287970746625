import React, { Component } from "react";
import api from "../api/apiUrl";
import { Redirect } from "react-router-dom";
import axios from "axios";
import soundfile from "../audio/ding.mp3";
import Sound from "react-sound";

class Banner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      msisdn: "",
      path: "",
      isTac: true,
      tac: Math.floor(Math.random() * Math.floor(999999)),
      enteredTac: "",
      success: "",
      isTacCorrect: false,
      errorLabel: false
      // isLoggedIn: false
    };
    this.modalRef = React.createRef();
  }

  componentDidMount() {
    //Get MSISDN
    axios.head("/", { params: {} }).then(response => {
      if (!localStorage.getItem("msisdn"))
        localStorage.setItem("msisdn", response.headers.msisdn || "");
      //this.setState({ msisdn: "601156649268" });
      console.log("MSISDN in HE:------> " + localStorage.getItem("msisdn"));
    });
  }

  tacHandler = async event => {
    event.preventDefault();
    try {
      // if (this.state.msisdn) localStorage.setItem("msisdn", this.state.msisdn);
      const res = await api
        .get("/api/sendTac", {
          params: {
            msisdn: this.state.msisdn,
            tac: this.state.tac
          }
        })
        .then(this.setState({ isTac: false }));
    } catch (err) {
      this.setState({ path: "/Error" });
    }
  };

  Subscribe = async event => {
    event.preventDefault();
    try {
      this.modalRef.current.click();
      if (this.state.msisdn) localStorage.setItem("msisdn", this.state.msisdn);

      const res = await api.get("/api/subscribe", {
        params: {
          msisdn: localStorage.getItem("msisdn")
          //msisdn: this.state.msisdn
        }
      });

      if (res.data.response === 100 || res.data.response === 400 || res.data.response === "Success") {
        this.setState({ path: "/SubscriptionSuccessful" });
        localStorage.setItem("isLoggedIn", true);
      } else if (res.data.response === 116) {
        localStorage.clear();
        this.setState({ path: "/InsufficientBalance" });
      } else if (res.data.response === 300) {
        this.setState({ path: "/AlreadySubscribed" });
        localStorage.setItem("isLoggedIn", true);
      } else {
        localStorage.clear();
        this.setState({ path: "/Error" });
      }
    } catch (err) {
      localStorage.clear();
      this.setState({ path: "/Error" });
    }
  };

  isSubscribed = async () => {
    const res = await api.get("/api/checkSubscription", {
      params: {
        msisdn: this.state.msisdn
      }
    });
    return res.data;
  };

  Login = async event => {
    event.preventDefault();

    //check subscription
    var isSubscribed = await this.isSubscribed(this.state.msisdn);
    console.log(isSubscribed.result);

    if (isSubscribed.result) {
      if (this.state.msisdn) localStorage.setItem("msisdn", this.state.msisdn);
      localStorage.setItem("isLoggedIn", true);
      window.location.reload();
    } else {
      this.setState({ errorLabel: true });
    }

    // window.location.reload();
    // this.modalRef.current.click();
  };
  renderErrorLabel() {
    if (this.state.errorLabel) {
      return (
        <label style={{ color: "red" }}>
          ** You are not subscribed. Please subscribe and try again. **
        </label>
      );
    }
  }
  renderContent() {
    if (!localStorage.getItem("isLoggedIn")) {
      if (localStorage.getItem("msisdn")) {
        return (
          <div className="carousel-text">
            <form onSubmit={this.Subscribe}>
              <button type="submit" href="#" className="btn btn-primary">
                Subscribe
              </button>
            </form>
          </div>
        );
      } else {
        return (
          <div className="carousel-text">
            <a
              href="#"
              className="btn btn-primary"
              data-toggle="modal"
              data-target="#LoginSubscribeModal"
            >
              Subscribe
            </a>
          </div>
        );
      }
    }
  }

  renderButton() {
    if (!this.state.isTacCorrect) {
      return (
        <button type="submit" className="btn btn-primary btn-block">
          Send TAC
        </button>
      );
    } else {
      return (
        <button
          onClick={this.Subscribe}
          type="submit"
          className="btn btn-primary btn-block"
        >
          Subscribe
        </button>
      );
    }
  }
  renderTextBox() {
    if (this.state.isTac) {
      return (
        <input
          required
          type="text"
          className="form-control"
          id="phone-number-sub"
          placeholder="xxxxxxx"
          value={this.state.msisdn}
          onChange={e => {
            const re = /^[0-9\b]+$/;
            if (e.target.value === "" || re.test(e.target.value)) {
              this.setState({ msisdn: e.target.value });
            }
          }}
        />
      );
    } else if (!this.state.isTac) {
      return (
        <div>
          <div
            style={{
              backgroundPosition: "right",
              backgroundRepeat: "no-repeat",
              width: "90%"
            }}
          >
            <input
              required
              type="text"
              className="form-control"
              id="phone-number-sub"
              placeholder="Enter pin"
              value={this.state.enteredTac}
              onChange={e => {
                const re = /^[0-9\b]+$/;
                if (e.target.value === "" || re.test(e.target.value)) {
                  this.setState({ enteredTac: e.target.value });
                  if (
                    e.target.value.length === 6 &&
                    e.target.value.toString() === this.state.tac.toString()
                  ) {
                    this.setState({ success: "ok" });
                    this.setState({ isTacCorrect: true });
                  } else {
                    this.setState({ success: "err" });
                    this.setState({ isTacCorrect: false });
                  }
                }
              }}
            />
          </div>
          <div> {this.loadImage()} </div>
        </div>
      );
    }
  }

  loadImage() {
    if (this.state.success === "ok")
      return (
        <div>
          <img
            style={{
              width: "35px",
              height: "35px",
              float: "right",
              marginTop: "-34px"
            }}
            src={require("../images/tickgif.gif")}
          ></img>
          <Sound url={soundfile} playStatus={Sound.status.PLAYING} />
        </div>
      );
    else if (this.state.success === "err")
      return (
        <img
          style={{
            width: "25px",
            height: "25px",
            float: "right",
            marginTop: "-32px"
          }}
          src={require("../images/error.png")}
        ></img>
      );
  }

  render() {
    if (this.state.path !== "") return <Redirect to={this.state.path} />;
    return (
      <div>
        <div id="banner">
          <div className="owl-carousel owl-theme box-banner" id="main-banner">
            <div>
              <div className="owl-text-overlay hidden-xs">
                {this.renderContent()}
              </div>
              <img
                rel="preload"
                className="owl-img  lazy"
                src="https://kcdn-92f3.kxcdn.com/xoxfunv2/resources/images/blank-img.png"
                data-src="https://kcdn-92f3.kxcdn.com/xoxfunv2/resources/images/banner/1.jpg?tr=w-888,h-618"
                alt="slider banner " width="118" height="36"
              />
            </div>
            <div>
              <div className="owl-text-overlay hidden-xs">
                {this.renderContent()}
              </div>
              <img
                className="owl-img  lazy"
                src="https://kcdn-92f3.kxcdn.com/xoxfunv2/resources/images/blank-img.png"
                data-src="https://kcdn-92f3.kxcdn.com/xoxfunv2/resources/images/banner/2.jpg?tr=w-888,h-618"
                alt="slider banner " width="118" height="36"
              />
            </div>
            <div>
              <div className="owl-text-overlay hidden-xs">
                {this.renderContent()}
              </div>
              <img
                className="owl-img  lazy"
                src="https://kcdn-92f3.kxcdn.com/xoxfunv2/resources/images/blank-img.png"
                data-src="https://kcdn-92f3.kxcdn.com/xoxfunv2/resources/images/banner/3.jpg?tr=w-888,h-618"
                alt="slider banner " width="118" height="36"
              />
            </div>
          </div>
        </div>
        <marquee style={{ color: "white", fontSize: "1.5em" }}>
          Subscribe and Play unlimited exciting games for just 3 MYR!!!
        </marquee>
        {/* Modal */}
        <div
          id="LoginSubscribeModal"
          className="modal fade"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="LoginSubscribeModal"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header login-subscribe">
                <nav className="login-modal">
                  <div className="nav nav-tabs" id="nav-tab" role="tablist">
                    <a
                      className="nav-item left nav-link active"
                      id="nav-subscribe-tab"
                      data-toggle="tab"
                      href="#subscribe"
                      role="tab"
                      aria-controls="nav-subscribe"
                      aria-selected="true"
                    >
                      Subscribe
                    </a>
                    <a
                      className="nav-item right nav-link"
                      id="nav-login-tab"
                      data-toggle="tab"
                      href="#login"
                      role="tab"
                      aria-controls="nav-login"
                      aria-selected="false"
                    >
                      Login
                    </a>
                  </div>
                </nav>
              </div>
              <div className="modal-body">
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="subscribe"
                    role="tabpanel"
                    aria-labelledby="subscribe-tab"
                  >
                    <form id="form-subscribe1" onSubmit={this.tacHandler}>
                      <div className="form-group">
                        <label
                          htmlFor="phone-number-sub"
                          className="col-form-label"
                        >
                          To subscribe send ON <b>FUN</b> to <b>20000</b> and
                          get unlimited access to play the best HTML5 Games NOW.
                        </label>
                        {/* <input
                          type="text"
                          className="form-control"
                          id="phone-number-sub"
                          placeholder="xxxxxxx"
                          value={this.state.msisdn}
                          onChange={e =>
                            this.setState({ msisdn: e.target.value })
                          }
                        /> */}
                        {this.renderTextBox()}
                      </div>
                      <div className="row">
                        <div className="col-6 pr-2">
                          <button
                            id="btnCancel"
                            type="button"
                            className="btn btn-outline-primary btn-block"
                            data-dismiss="modal"
                          >
                            Cancel
                          </button>
                        </div>
                        <div className="col-6 pl-2">
                          {/* <button
                            type="submit"
                            className="btn btn-primary btn-block"
                          >
                            Send TAC
                          </button> */}
                          {this.renderButton()}
                        </div>
                      </div>
                    </form>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="login"
                    role="tabpanel"
                    aria-labelledby="login-tab"
                  >
                    <form id="form-login" onSubmit={this.Login}>
                      <div className="form-group">
                        <label
                          htmlFor="phone-number-login"
                          className="col-form-label"
                        >
                          Please key in your phone number. For{" "}
                          <b className="text-red">XOX's</b> customers only
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="phone-number-login"
                          placeholder="xxxxxxx"
                          value={this.state.msisdn}
                          onChange={e => {
                            const re = /^[0-9\b]+$/;
                            if (
                              e.target.value === "" ||
                              re.test(e.target.value)
                            ) {
                              this.setState({ msisdn: e.target.value });
                            }
                          }}
                        />
                        {this.renderErrorLabel()}
                      </div>
                      <div className="row">
                        <div className="col-6 pr-2">
                          <button
                            type="button"
                            className="btn btn-outline-primary btn-block"
                            data-dismiss="modal"
                            ref={this.modalRef}
                          >
                            Cancel
                          </button>
                        </div>
                        <div className="col-6 pl-2">
                          <button
                            type="submit"
                            className="btn btn-primary btn-block"
                          >
                            Login
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Banner;
