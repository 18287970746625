import React, { Component } from "react";
import Header, { CampaignHeader } from "./Header";
import Footer, { CampaignFooter } from "./Footer";

class Layout extends Component {
  render() {
    return (
      <div>
        <Header />
        {this.props.children}
        <Footer />
      </div>
    );
  }
}

class CampaignLayout extends Component {
  render() {
    return (
      <div>
        <CampaignHeader />
        {this.props.children}
        <CampaignFooter />
      </div>
    );
  }
}

export default Layout;
export { CampaignLayout };
