import React, { Component } from "react";
import { CampaignLayout } from "../components/Layout";
import * as QueryString from "query-string";
import Background from "../images/background.jpg";
import TagManager from "react-gtm-module";
import api from "../api/apiUrl";
import axios from "axios";
import { Redirect } from "react-router-dom";

class GamesOn extends Component {
  state = { txid: "", msisdn: "111111", path: "" };
  componentDidMount() {
    //load empello script
    const script = document.createElement("script");
    script.src = "//antifraud.empello.net/azeyihnj/owzrsdtv-live.js";
    document.body.appendChild(script);

    const tagManagerArgs = {
      gtmId: "GTM-5WJ954P",
    };
    TagManager.initialize(tagManagerArgs);
    const params = QueryString.parse(this.props.location.search);
    this.setState({ txid: params.txid });
    
    api
      .get("/campaign/AddCampaignDetails", {
        params: {
          msisdn: this.state.msisdn,
          txid: params.txid,
          affid: params.affid,
          pageid: params.pageid,
          pubid: params.pubid,
        },
      })
      .then((response) => {
        console.log(response);
      });
  }

  empelloTokenValidation = async (token) => {
    const IP = await axios.get("https://api.ipify.org?format=json");
    var bodyFormData = new FormData();
    bodyFormData.append("api_key", "CLpPLuETtkGk7uFP");
    bodyFormData.append("timestamp", new Date().toISOString());
    bodyFormData.append("user_ip", IP.data.ip);
    //bodyFormData.append("user_ip", "210.5.41.101");
    bodyFormData.append("user_agent", window.navigator.userAgent);
    bodyFormData.append("token", token);

    const result = await axios({
      method: "post",
      url: "https://antifraud.empello.net/api/v1/token/validate/",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    });
    console.log(result);
    if (result.data.is_valid) this.subscribeHandler();
    else this.setState({ path: "/Error" });
  };

  getEmpelloToken = async () => {
    window.Empello.getToken(async (token) => {
      await this.empelloTokenValidation(token);
    });
  };

  subscribeHandler = async () => {
    await axios
      .head("/", { params: {} })
      .then((response) => this.setState({ msisdn: response.headers.msisdn }));
    try {
      //Subscribe
      const res = await api.get("/subscribe", {
        params: {
          msisdn: this.state.msisdn,
        },
      });

      if (res.data.response === 100 || res.data.response === 400) {
        //CM
        api.get("/FirebackCM", {
          param: {
            msisdn: this.state.msisdn,
            txid: this.state.txid,
          },
        });

        //Check response
        this.setState({ path: "/SubscriptionSuccessful" });
      } else if (res.data.response === 116)
        this.setState({ path: "/InsufficientBalance" });
      else if (res.data.response === 300)
        this.setState({ path: "/AlreadySubscribed" });
      else this.setState({ path: "/Error" });
    } catch (err) {
      this.setState({ path: "/Error" });
    }
  };

  render() {
    if (this.state.path !== "") return <Redirect to={this.state.path} />;
    return (
      <div
        style={{
          background: `url(${Background})`,
          height: "100%",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <CampaignLayout>
          <center>
            <div className="card" style={{ width: "30rem", height: "33rem" }}>
              <img
                className="card-img-top"
                src={require("../images/gamesOn.jpg")}
                alt="img"
                height="320px"
              />
              <br />
              <div className="card-body">
                <h5 className="card-title">
                  No Ads, No Downloads <br /> Just Awesome Games
                </h5>
                <p className="card-text">
                  <i>What are you waiting for?</i>
                </p>
                <a
                  href="/"
                  className="btn btn-primary"
                  onClick={this.getEmpelloToken}
                >
                  Play Now
                </a>
              </div>
            </div>
          </center>
        </CampaignLayout>
      </div>
    );
  }
}

export default GamesOn;
