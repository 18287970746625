import React, { Component } from "react";
import Layout from "./Layout";
import subApi, { gamesApi } from "../api/apiUrl";

class GameDetail extends Component {
  state = { game: "", isSubscribed: false };
  componentDidMount() {
    gamesApi
      .get("/gamedetail", {
        params: {
          id: this.props.match.params.GameID
        }
      })
      .then(response => this.setState({ game: response.data }));

    subApi
      .get("/api/checkSubscription", {
        params: {
          //msisdn: this.state.msisdn
          msisdn: localStorage.getItem("msisdn")
        }
      })
      .then(response => this.setState({ isSubscribed: response.data.result }));
    //.then(response => console.log(response.data.result));
  }

  renderContent() {
    if (this.state.isSubscribed) {
      return (
        <div className="card-links d-flex ml-auto">
          <div className="col-12 pl-1 pr-3 pr-sm-4">
            <a
              className="btn btn-primary btn-inline-block"
              href={this.state.game.GameAddress}
            >
              Play
            </a>
          </div>
        </div>
      );
    } else if (!this.state.isSubscribed) {
      return (
        <div className="card-links d-flex ml-auto">
          <div className="col-12 pl-1 pr-3 pr-sm-4">
            <a className="btn btn-primary btn-inline-block" href="/">
              Subscribe
            </a>
          </div>
        </div>
      );
    }
  }
  render() {
    return (
      <div>
        <Layout>
          <section className="main-content pt-nav">
            <div className="container">
              <div className="game-cat-section">
                <div className="card game-profile text-center">
                  <div className="card-img-top fullwidth-img game-header">
                    <br />
                  </div>
                  <div className="card-block">
                    <img
                      className="game-img-profile float-left"
                      src={this.state.game.GameLogo}
                    />
                    <h4 className="card-title">
                      {this.state.game.GameTitle}
                      <small>
                        <a href="game-category.html" className="cat-link">
                          {this.state.game.CategoryName}
                        </a>
                      </small>
                    </h4>
                    {this.renderContent()}
                  </div>
                  <div className="card-body">
                    <div className="container pl-2 pr-2">
                      <h5 className="game-title-desc">Description</h5>
                      <p className="game-desc">
                        {this.state.game.GameDescription}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Layout>
      </div>
    );
  }
}

export default GameDetail;
