import React, { Component } from "react";
import { CampaignLayout } from "../components/Layout";
import * as QueryString from "query-string";
import Background from "../images/background.jpg";
import TagManager from "react-gtm-module";
import api from "../api/apiUrl";
import axios from "axios";
import { Redirect } from "react-router-dom";

class BestGames extends Component {
    state = { txid: "", msisdn: "111111", path: "" };
    componentDidMount() {

        const params = QueryString.parse(this.props.location.search);
        api.get("api/GetEvina", {
            params: {
                txid: params.txid
            }
        })
        .then((response) => {
            console.log(response.data);
            const script = document.createElement("script");
            script.set = response.data;
            document.head.append(script);
        });

        const tagManagerArgs = {
            gtmId: "GTM-5WJ954P",
        };
        TagManager.initialize(tagManagerArgs);
        this.setState({ txid: params.txid });



        api
            .get("/campaign/AddCampaignDetails", {
                params: {
                    msisdn: this.state.msisdn,
                    txid: params.txid,
                    affid: params.affid,
                    pageid: params.pageid,
                    pubid: params.pubid,
                },
            })
            .then((response) => {
                console.log(response);
            });
    }
    subscribeHandler = async () => {
        await axios
            .head("/", { params: {} })
            .then((response) => this.setState({ msisdn: response.headers.msisdn }));
        try {
            //Subscribe
            const res = await api.get("/subscribe", {
                params: {
                    msisdn: this.state.msisdn,
                },
            });

            if (res.data.response === 100 || res.data.response === 400) {
                //CM
                api.get("/FirebackCM", {
                    param: {
                        msisdn: this.state.msisdn,
                        txid: this.state.txid,
                    },
                });

                //Check response
                this.setState({ path: "/SubscriptionSuccessful" });
            } else if (res.data.response === 116)
                this.setState({ path: "/InsufficientBalance" });
            else if (res.data.response === 300)
                this.setState({ path: "/AlreadySubscribed" });
            else this.setState({ path: "/Error" });
        } catch (err) {
            this.setState({ path: "/Error" });
        }
    };

    render() {
        if (this.state.path !== "") return <Redirect to={this.state.path} />;
        return (
            // <div style={require("../css/BestGames.css")}>
            //     <div className="main-content fit-screen-outer">
            //         <div className="container fit-screen-inner">
            //             <div className="sixteen columns centerx">
            //                 <img src={require("../images/BestGames.png")} width="300" height="263" className="large-imgx show" layout="responsive" />
            //             </div>

            //             <a href="#" className="Subbtn button-propeties buttonx2 show" onClick={this.subscribeHandler}><b>JOIN NOW</b></a>


            //             <div className="sixteen columns tnc-info centerx">
            //                 <div className="accordion full-bottom ">
            //                     <section>
            //                         <h2 className="tnc-title centerx show"><b>Terms &amp; Conditions</b></h2>
            //                         <ul>
            //                             <div className="tnc-content centerx show">
            //                                 This is a mobile content subscription service. This service is applicable for <b>XOX&nbsp;</b><b>Mobile users only.</b> You will subscribe to XOXFun for RM 3/week. Portal can be viewed on any mobile device with minimum OS such as iOS devices version 3.0+ or Android devices version 2.0. To unsubscribe from XOXFun, text <b>STOP FUN</b>&nbsp;to <b>20000.&nbsp;</b>
            //                             </div>
            //                         </ul>
            //                     </section>
            //                 </div>
            //             </div>
            //         </div>
            //     </div>
            // </div>
            <div
                style={{
                    background: `url(${Background})`,
                    height: "100%",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                }}
            >
                <CampaignLayout>
                    <center>
                        <div className="card" style={{ width: "25rem", height: "38rem", backgroundColor: "#D433FF" }}>
                            <img
                                className="card-img-top"
                                src={require("../images/BestGames.png")}
                                alt="img"
                                height="320px"
                            />
                            <br />
                            <div className="card-body">

                                <a
                                    href="/"
                                    className="btn btn-primary"
                                    onClick={this.subscribeHandler}
                                    style={{border: "5px solid #cfc4a5",
                                            padding: "10px 0px 12px 0px",
                                            text: "center",
                                            width: "200px",
                                            margin: "0px 50px 13px 50px",
                                            color:"#000000",
                                            backgroundImage:"linear-gradient(to bottom, #ffbb00, #fb9623)",
                                            fontSize:"20px",
                                            MozBoxShadow:"3px 5px 0px 0px #000000"}}
                                >
                                    Join Now
                                </a>
                            </div>
                            <section>
                                <h4 className="centerx temrs-className show" style={{background:"transparent", border:"none", color:"#ffffff", fontSize:"15px"}} role="button" aria-controls="_AMP_content_0" aria-expanded="false" tabindex="0"><b><br />Terms &amp; Conditions</b> </h4> <br/>
                                <div className="content" id="_AMP_content_0">
                                    <ol className="full-bottom tnc">
                                        <div className="centerx li4 show" style={{fontSize:"11px",color:"#ffffff"}}>
                                            This is a mobile content subscription service. This service is applicable for&nbsp;<b>XOX&nbsp;</b><b>Mobile users only.</b>&nbsp;You will subscribe to XOXFun for RM 3/week. Portal can be viewed on any mobile device with minimum OS such as iOS devices version 3.0+ or Android devices version 2.0. To unsubscribe from XOXFun, text&nbsp;<b>STOP FUN</b>&nbsp;to&nbsp;<b>20000.&nbsp;</b>
                                        </div>
                                        <br />
                                    </ol>
                                </div>
                            </section>
                        </div>
                    </center>
                </CampaignLayout>
            </div>

        );
    }


}
export default BestGames;