import React, { Component } from "react";
import { Route, BrowserRouter as Router } from "react-router-dom";

import GameDetails from "./components/GameDetails";
import GamesOn from "./campaign/GamesOn";
import Home from "./App";
import InsufficientBalance from "./components/InsufficientBalance";
import AlreadySubscribed from "./components/AlreadySubscribed";
import SubscriptionSuccessful from "./components/SubscriptionSuccessful";
import Error from "./components/Error";
import Category from "./components/Category";
import FAQ from "./components/FAQ";
import TnC from "./components/T&C";
import Search from "./components/Search";
import GamingHeaven from "./campaign/GamingHeaven";
import BestGames from "./campaign/BestGames";
import AllGames from "./components/AllGames";

class Routes extends Component {
  render() {
    return (
      <div>
        <Router>
          <Route exact path="/" component={Home} />
          <Route path="/gamedetails/:GameID" component={GameDetails} />
          <Route path="/campaign/GamesOn/" component={GamesOn} />
          <Route path="/campaign/GamingHeaven" component={GamingHeaven} />
          <Route path="/campaign/BestGames" component={BestGames} />
          <Route
            exact
            path="/InsufficientBalance/"
            component={InsufficientBalance}
          />
          <Route
            exact
            path="/AlreadySubscribed/"
            component={AlreadySubscribed}
          />
          <Route
            exact
            path="/SubscriptionSuccessful/"
            component={SubscriptionSuccessful}
          />
          <Route exact path="/Error" component={Error} />
          <Route exact path="/category/:category" component={Category} />
          <Route exact path="/faq" component={FAQ} />
          <Route exact path="/tnc" component={TnC} />
          <Route path="/search/" component={Search} />
          <Route path="/AllGames/" component={AllGames} />
        </Router>
      </div>
    );
  }
}

export default Routes;
