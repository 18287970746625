import React, { Component } from "react";
import { gamesApi } from "../api/apiUrl";
import Layout from "./Layout";

export default class Category extends Component {
  state = { category: [], key: Math.random() };
  componentDidMount() {
    console.log(this.props);
    gamesApi
      .get("/GetGamesBycategory", {
        params: {
          category: this.props.match.params.category
        }
      })
      .then(response => this.setState({ category: response.data }));
  }
  gameList() {
    return this.state.category.map(category => {
      return (
        <div key={this.state.key} className="col-4 col-md-2 pl-0 pr-1">
          <div className="card">
            <a href={`/gameDetails/${category.GameID}`}>
              <img
                className="card-img-top"
                src={category.GameLogo}
                alt="Game 1"
              />
            </a>
            <div className="card-body">
              <p className="game-title">
                <a href={`/gameDetails/${category.GameID}`}>
                  {category.GameTitle}r
                </a>
              </p>
            </div>
            <div className="card-footer">
              <a className="game-cat">{category.CategoryName}</a>
            </div>
          </div>
        </div>
      );
    });
  }
  render() {
    return (
      <Layout>
        <section className="main-content pt-nav">
          <div className="container">
            <div className="game-cat-section">
              <div className="container   pl-2 pr-2">
                <div className="d-flex flex-column pb-2">
                  <div className="section-header up-margin">
                    {this.state.category.CategoryName}
                  </div>
                </div>
                <div className="game-group row m-0">{this.gameList()}</div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
