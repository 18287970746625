import React, { Component } from "react";
import Layout from "./Layout";

export default class TnC extends Component {
  render() {
    return (
      <Layout>
        <section className="main-content pt-nav">
          <div className="container">
            <div className="game-cat-section ">
              <div className="card box-content-main ">
                <div className="card-body ">
                  <div className="container pl-2 pr-2">
                    <h2 className="title"> XOX FUN TERMS OF SERVICE </h2>
                    <p>
                      HTML5 Games by XOX MOBILE is maintained by Forest
                      Interactive Sdn Bhd. All the HTML5 Games from the mobile
                      interface <b>(www.xoxfun.com)</b> are subject to these
                      Term of Use.
                    </p>
                    <ol className="blue-line">
                      <li>
                        <b>XOX HTML5 Games</b> from XOX MOBILE allow you to play
                        games on your preferred device choice that is compatible
                        for the games to be played on such as Mobile or Tablet.{" "}
                      </li>
                      <li>
                        <b>Payment and Service Access</b>. You can subscribe to
                        the service through portal by clicking the subscribe
                        button and will receive SMS notification which will be
                        sent to registered number. Or user can subscribe by
                        sending <b>ON FUN</b> to <b>20000</b>.{" "}
                      </li>
                      <li>
                        <b>Price and payment</b> is expressly stated that cost
                        of connection to the service supported by the user,
                        which include the content downloaded by users. This
                        shall remain at the responsibility of the User.{" "}
                      </li>
                      <li>
                        <b>Rules for use</b> of the service principles governing
                        the use of service which exclusively reserved for a
                        reasonable, non-commercial use.{" "}
                      </li>
                      <li>
                        <b>Responsibilities</b>. You understand the
                        characteristic and limitations of electronic
                        communications networks, including the technical
                        performance, response, querying, transferring data and
                        risk to information security.{" "}
                      </li>
                      <li>
                        <b>Termination of service subscription</b>, user need to
                        send <b>STOP FUN</b> to <b>20000</b>.{" "}
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
