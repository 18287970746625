import React, { Component } from "react";

export default class Games extends Component {
  gamesList() {
    return this.props.games.map(game => {
      return (
        <div key={game.GameID} className="cat-slider swiper-slide">
          <div className="img-box">
            <a href={`/gameDetails/${game.GameID}`}>
              <img
                //src="https://kcdn-92f3.kxcdn.com/xoxfunv2/resources/images/blank-img.png"
                src={game.GameLogo}
                className="games-thum img-responsive img-fluid lazy "
                loading="lazy"
                alt=""
                width="135" height="135"
              />
            </a>
          </div>
          <div className="thumb-content">
            <h4>
              <a href={`/gameDetails/${game.GameID}`}>{game.GameTitle}</a>
            </h4>
            <a style={{ color: "red" }}>{this.props.category}</a>
          </div>
        </div>
      );
    });
  }
  render() {
    return (
      <div
        className="swiper-wrapper"
        style={{
          marginLeft: "32%",
          overflowX: "hidden",
          overflowX: "auto",
          width: "auto",
          overflowY: "hidden"
        }}
      >
        {this.gamesList()}
      </div>
    );
  }
}
