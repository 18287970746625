import React, { Component } from "react";
import Games from "./Games";

export default class GameCategories extends Component {
  state = {
    colors: ["red", "blue", "green", "pink"]
  };

  gameCategoryList() {
    const { colors } = this.state;
    const color = colors[Math.floor(Math.random(4) * colors.length)];
    return this.props.categories.map((category, index) => {
      return (
        <div key={index}>
          <div className="d-flex">
            <div className="container section-title">
              <div className="ml-0 section-header float-left">&nbsp;</div>
              <div className="float-right d-flex  navig-float">
                        <button className="arrow-box" id={category.CategoryName.toLowerCase() + "-prev"} aria-label="left arrow"><img src="https://kcdn-92f3.kxcdn.com/xoxfunv2/resources/images/left-chevron.svg" width="11" height="16" alt=""/></button>
                        <button className="arrow-box" id={category.CategoryName.toLowerCase() + "-next"} aria-label="right arrow"><img src="https://kcdn-92f3.kxcdn.com/xoxfunv2/resources/images/right-chevron.svg" width="11" height="16" alt=""/></button>
              </div>
            </div>
          </div>

          <div
            id={category.CategoryName.toLowerCase() + "-carousel"}
            className={`bg-${color} swiper-container`}
          >
            <div
              className={
                category.CategoryName.toLowerCase() + "-header cat-header"
              }
            >
              <div className="d-flex flex-column">
                <div className="justify-content-center p-0 text-center">
                  <i
                    className="cat-icon ion-ios-game-controller"
                    aria-hidden="true"
                  ></i>
                </div>
                <div className="justify-content-center p-0 text-center">
                  <h4 className="cat-text">{category.CategoryName}</h4>
                </div>
                <div className="justify-content-center p-0 text-center">
                  <a
                    href={`/Category/${category.CategoryName}`}
                    className="btn btn-outline-cat"
                  >
                    See More
                  </a>
                </div>
              </div>
            </div>

            <Games games={category.Games} category={category.CategoryName} />
          </div>
        </div>
      );
    });
  }

  render() {
    return <div>{this.gameCategoryList()}</div>;
  }
}
