import React, { Component } from "react";

class Footer extends Component {
  state = { isMsisdnPresent: false };
  componentDidMount() {
    if (localStorage.getItem("msisdn")) {
      this.setState({ isMsisdnPresent: true });
    }
  }

  renderUnsubscribeLogout() {
    if (this.state.isMsisdnPresent) {
      return (
        <div>
          <li>
            <a
              href="/"
              className="btn-underline"
              data-toggle="modal"
              data-target="#UnSubscribeModal"
            >
              Unsubscribe
            </a>
          </li>

          <li style={{ marginLeft: "10px" }}>
            <a
              href="#"
              onClick={() => {
                localStorage.clear();
                //this.setState({ msisdn: "" });
              }}
              className="btn-underline"
              data-toggle="modal"
              data-target="#LogoutModal"
            >
              Logout
            </a>
          </li>
        </div>
      );
    }
  }
  onFormSubmit = async event => {
    event.preventDefault();
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/windows phone/i.test(userAgent)) {
      alert("Not Supported");
      return;
    }
    if (/android/i.test(userAgent)) {
      window.location.href = "sms:20000" + "?body=STOP FUN";
      return;
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href = "sms:20000" + "&body=STOP FUN";
      return;
    } else {
      alert("Please SMS  STOP FUN to 20000");
    }
  };

  render() {
    return (
      <div>
        <footer className="bd-footer footer">
          <div className="container-fluid p-3">
            <ul className="bd-footer-links">
              <li>
                <a href="/faq" className="p-2">
                  FAQ
                </a>
              </li>
              <li>
                <a href="/tnc" className="p-2">
                  T&C
                </a>
              </li>
              {this.renderUnsubscribeLogout()}
            </ul>

            <p>
              XOX MOBILE Copyright @ 2018 <br />
              All Rights Reserved
            </p>
          </div>
        </footer>

        <div
          id="LogoutModal"
          className="modal fade"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="LogoutModal"
          aria-hidden="true"
          onMouseDown={() => window.location.reload()}
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-body top-red">
                <div className="unsubscribe d-flex flex-column">
                  <div
                    className="pop-banner"
                    style={{ WebkitTextEmphasisStyle: "open" }}
                  >
                    <h4>You are successfully logged out.</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          id="UnSubscribeModal"
          className="modal fade"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="UnSubscribeModal"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-body top-red">
                <div className="unsubscribe d-flex flex-column">
                  <div className="unsubscribe-header text-center">
                    <h2>It's sad to see you go. </h2>
                  </div>
                  <div className="pop-banner">
                    <img
                      className="img-fluid"
                      src="https://kcdn-92f3.kxcdn.com/xoxfunv2/resources/images/kitty.png"
                    />
                    <p>Please SMS STOP FUN to 20000</p>
                  </div>
                </div>
                <form id="form-unsubscribe" onSubmit={this.onFormSubmit}>
                  <div className="row">
                    <div className="col-12 box-unsub">
                      <button
                        type="submit"
                        className=" btn-outline-primary btn-inlidne"
                        // data-dismiss="modal"
                      >
                        Unsubscribe
                      </button>
                      <button
                        type="button"
                        className=" btn-primary btn-idnline"
                        data-dismiss="modal"
                      >
                        I'll change my mind
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class CampaignFooter extends Component {
  render() {
    return (
      <div>
        <footer className="bd-footer footer">
          <div className="container-fluid p-3">
            <ul className="bd-footer-links">
              <li>
                <a href="/faq" className="p-2">
                  FAQ
                </a>
              </li>
              <li>
                <a href="/tnc" className="p-2">
                  T&C
                </a>
              </li>
            </ul>

            <p>
              XOX MOBILE Copyright @ 2018 <br />
              All Rights Reserved
            </p>
          </div>
        </footer>
      </div>
    );
  }
}
export default Footer;
export { CampaignFooter };
