import axios from "axios";

const subApi = axios.create({
  baseURL: "http://210.5.41.101/xoxfunapi/"
  //baseURL: "http://192.168.28.110:5006/api"
  //baseURL: "http://localhost:3001/api"
  // headers: {
  //   "Access-Control-Allow-Origin": "*"
  // }
});

const gamesApi = axios.create({
  baseURL: "http://xoxplanet.com/xoxfun"
  // headers: {
  //   "Access-Control-Allow-Origin": "*"
  // }
});

const gameCatApi = axios.create({
  baseURL: "https://kcdn-92f3.kxcdn.com/xoxfunv2/resources"
});

export default subApi;
export { gamesApi };
export { gameCatApi };