import React, { Component } from "react";

export default class NewGames extends Component {
  render() {
    return (
      <div>
        <div className="recommended d-flex">
          <div className="container section-title">
            <div className="ml-0 section-header float-left">New Games</div>
            <div className="float-right d-flex  navig-float">
              <button className="arrow-box" id="RecomenSlider-prev">
                <i className="ion-chevron-left"></i>
              </button>
              <button className="arrow-box" id="RecomenSlider-next">
                <i className="ion-chevron-right"></i>
              </button>
            </div>
          </div>
        </div>

        <div className="recommended">
          <div
            id="recommended-slide"
            className="recommended-slide swiper-container pt-0"
          >
            <div className="swiper-wrapper">
              <div className="cat-slider swiper-slide">
                <div className="img-box">
                  <a href="/gameDetails/">
                    <img
                      src="https://kcdn-92f3.kxcdn.com/xoxfunv2/resources/images/game-icon/game1.png"
                      className="games-thum img-responsive img-fluid"
                      alt=""
                    />
                  </a>
                </div>
                <div className="thumb-content">
                  <h4>
                    <a href="/gameDetails/">Groovy Ski</a>
                  </h4>
                  <a href="game-category.html">Arcade</a>
                </div>
              </div>
              <div className="cat-slider swiper-slide">
                <div className="img-box">
                  <a href="/gameDetails/">
                    <img
                      src="https://kcdn-92f3.kxcdn.com/xoxfunv2/resources/images/game-icon/game2.png"
                      className="games-thum img-responsive img-fluid"
                      alt=""
                    />
                  </a>
                </div>
                <div className="thumb-content">
                  <h4>
                    <a href="/gameDetails/">Basketball Master</a>
                  </h4>
                  <a href="game-category.html">Physics</a>
                </div>
              </div>
              <div className="cat-slider swiper-slide">
                <div className="img-box">
                  <a href="/gameDetails/">
                    <img
                      src="https://kcdn-92f3.kxcdn.com/xoxfunv2/resources/images/game-icon/game3.png"
                      className="games-thum img-responsive img-fluid"
                      alt=""
                    />
                  </a>
                </div>
                <div className="thumb-content">
                  <h4>
                    <a href="/gameDetails/">Cheese Lab</a>
                  </h4>
                  <a href="game-category.html">Puzzle</a>
                </div>
              </div>
              <div className="cat-slider swiper-slide">
                <div className="img-box">
                  <a href="/gameDetails/">
                    <img
                      src="https://kcdn-92f3.kxcdn.com/xoxfunv2/resources/images/game-icon/game1.png"
                      className="games-thum img-responsive img-fluid"
                      alt=""
                    />
                  </a>
                </div>
                <div className="thumb-content">
                  <h4>
                    <a href="/gameDetails/">Groovy Ski</a>
                  </h4>
                  <a href="game-category.html">Arcade</a>
                </div>
              </div>
              <div className="cat-slider swiper-slide">
                <div className="img-box">
                  <a href="/gameDetails/">
                    <img
                      src="https://kcdn-92f3.kxcdn.com/xoxfunv2/resources/images/game-icon/game2.png"
                      className="games-thum img-responsive img-fluid"
                      alt=""
                    />
                  </a>
                </div>
                <div className="thumb-content">
                  <h4>
                    <a href="/gameDetails/">Basketball Master</a>
                  </h4>
                  <a href="game-category.html">Arcade</a>
                </div>
              </div>
              <div className="cat-slider swiper-slide">
                <div className="img-box">
                  <a href="/gameDetails/">
                    <img
                      src="https://kcdn-92f3.kxcdn.com/xoxfunv2/resources/images/game-icon/game3.png"
                      className="games-thum img-responsive img-fluid"
                      alt=""
                    />
                  </a>
                </div>
                <div className="thumb-content">
                  <h4>
                    <a href="/gameDetails/">Cheese Lab</a>
                  </h4>
                  <a href="game-category.html">Arcade</a>
                </div>
              </div>
              <div className="cat-slider swiper-slide">
                <div className="img-box">
                  <a href="/gameDetails/">
                    <img
                      src="https://kcdn-92f3.kxcdn.com/xoxfunv2/resources/images/game-icon/game1.png"
                      className="games-thum img-responsive img-fluid"
                      alt=""
                    />
                  </a>
                </div>
                <div className="thumb-content">
                  <h4>
                    <a href="/gameDetails/">Groovy Ski</a>
                  </h4>
                  <a href="game-category.html">Arcade</a>
                </div>
              </div>
              <div className="cat-slider swiper-slide">
                <div className="img-box">
                  <a href="/gameDetails/">
                    <img
                      src="https://kcdn-92f3.kxcdn.com/xoxfunv2/resources/images/game-icon/game2.png"
                      className="games-thum img-responsive img-fluid"
                      alt=""
                    />
                  </a>
                </div>
                <div className="thumb-content">
                  <h4>
                    <a href="/gameDetails/">Basketball Master</a>
                  </h4>
                  <a href="game-category.html">Arcade</a>
                </div>
              </div>
              <div className="cat-slider swiper-slide">
                <div className="img-box">
                  <a href="/gameDetails/">
                    <img
                      src="https://kcdn-92f3.kxcdn.com/xoxfunv2/resources/images/game-icon/game3.png"
                      className="games-thum img-responsive img-fluid"
                      alt=""
                    />
                  </a>
                </div>
                <div className="thumb-content">
                  <h4>
                    <a href="/gameDetails/">Cheese Lab</a>
                  </h4>
                  <a href="game-category.html">Arcade</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
