import React, { Component } from "react";
import Layout from "./components/Layout";
import Banner from "./components/Banner";
import NewGames from "./components/NewGames";
import GameCategories from "./components/GameCategories";
import { gamesApi } from "./api/apiUrl";
import { gameCatApi } from "./api/apiUrl";

class App extends Component {
  state = { games: [] };

  componentDidMount() {
    gameCatApi.get("/GameCategories.json").then(
      response => this.setState({ games: response.data })
      //console.log(response)
    );
  }

  render() {
    return (
      <div>
        <Layout>
          <section className="main-content">
            <div className="container">
              <Banner />
              {/* <NewGames /> */}
              <GameCategories categories={this.state.games} />
            </div>
            {/* <div className="text-center box-more">
              <a href="all-games.html" className="btn btn-primary">
                View All Games
              </a>
            </div> */}
            <div className="text-center box-more">
              <a href="/AllGames" className="btn btn-primary" aria-label="all games">View All Games</a>
            </div>
          </section>
        </Layout>
      </div>
    );
  }
}

export default App;
