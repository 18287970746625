import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { gamesApi } from "../api/apiUrl";
import TagManager from "react-gtm-module";

class Header extends Component {
  state = { searchTerm: "", games: [], open: false };
  componentDidMount() {
    const tagManagerArgs = {
      gtmId: "GTM-5WJ954P",
    };
    TagManager.initialize(tagManagerArgs);
  }
  onSubmit = async e => {
    e.preventDefault();
    this.setState({ flag: true });
    this.fetchData(this.state.searchTerm);
  };

  fetchData = term => {
    try {
      gamesApi
        .get("/SearchGames", {
          params: {
            title: term
          }
        })
        .then(response => {
          this.setState({ games: response.data });
          this.props.history.push({
            pathname: "/search",
            state: { games: this.state.games }
          });
        });
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    return (
      <div>
        {/* <div id="loader-wrapper">
          <div id="loader"></div>
          <div className="loader-section section-left"></div>
          <div className="loader-section section-right"></div>
        </div> */}
        <section className="nav-bg">
          <nav className="navbar navbar-expand-md navbar-light nav-overlay mb-4">
            <div className="container p-0">
              <div className="d-flex flex-row">
              <a className="navbar-toggler js-offcanvas-trigger" data-offcanvas-trigger="off-canvas-left">
                        <span className="navbar-toggler-icon"></span></a>
                <a className="navbar-brand" href="/" aria-label="navbar"></a>
              </div>
              <div className="d-flex flex-row-reverse">
                <a
                  className="search-toggler js-offcanvas-trigger"
                  data-toggle="collapse"
                  data-target="#searchForm"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                >
                  <span className="search-toggler-icon"></span>
                </a>
              </div>
            </div>
          </nav>
          <div className="collapse" id="searchForm">
            <div className="container search-form">
              <form id="form-search" onSubmit={this.onSubmit}>
                <div className="row">
                  <div className="col-12">
                    <input
                      required
                      minLength="3"
                      type="text"
                      className="form-control form-control-lg"
                      placeholder="Search...."
                      value={this.state.searchTerm}
                      onChange={e =>
                        this.setState({ searchTerm: e.target.value })
                      }
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
        <aside className="js-offcanvas" id="off-canvas-left" role="complementary">
          <div className="c-offcanvas__inner o-box u-pos-relative">
            <ul className="navbar-nav c-menu c-menu--border u-unstyled">
              <li className="c-menu_item c-menu_item--heading">
                    <div className="nav-avatar">
                        <img className=" lazy" data-src="https://kcdn-92f3.kxcdn.com/xoxfunv2/resources/images/avatar-default.png" src="https://kcdn-92f3.kxcdn.com/xoxfunv2/resources/images/blank-img.png" width="160" height="90" alt=" "/>
                    </div>
                    <h1 className="nav-profile-name text-white">Visitor</h1>
                </li>
                <li className="c-menu_item">
                  <a className="c-menu_link" href="/">Home</a>
                </li>
                <li className="c-menu_item nav-item dropdown show">
                    <a className="c-menu_link nav-link dropdown-toggle show" href="#" id="dropdown10" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" aria-label="menu">Games Categories</a>
                    <div className="dropdown-menu show" aria-labelledby="dropdown10">
                        <a className="dropdown-item c-menu_dropdown" href="/Category/Puzzle" aria-label="menu">Puzzle</a>
                        <a className="dropdown-item c-menu_dropdown" href="/Category/Arcade" aria-label="menu">Arcade</a>
                        <a className="dropdown-item c-menu_dropdown" href="/Category/Physics" aria-label="menu">Physics</a>
                        <a className="dropdown-item c-menu_dropdown" href="/Category/Shooting" aria-label="menu">Shooting</a>
                    </div>
                </li>
            </ul>
          </div>
        </aside>
      </div>
    );
  }
}

class CampaignHeader extends Component {
  render() {
    return (
      <div>
        <section className="nav-bg">
          <nav className="navbar navbar-expand-md navbar-light nav-overlay mb-4">
            <div className="container p-0">
              <div className="d-flex flex-row">
                <a className="navbar-brand" href="/"></a>
              </div>
              <div className="d-flex flex-row-reverse"></div>
            </div>
          </nav>
        </section>
      </div>
    );
  }
}
export default withRouter(Header);
export { CampaignHeader };
