import React, { Component } from "react";
import Layout from "../components/Layout";

class SubscriptionSuccessful extends Component {
  render() {
    return (
      <div>
        <Layout>
          <div>
            <section className="main-content pt-nav">
              <div className="container">
                <div className="game-cat-section ">
                  <div className="card box-content-main ">
                    <div className="card-body ">
                      <div className="container pl-2 pr-2 text-center">
                        <h2 className="title">Yayyy!</h2>
                        <img
                          className="img-error"
                          src="https://kcdn-92f3.kxcdn.com/xoxfunv2/resources/images/robot.png"
                        />
                        <p>
                          You have subscribed successfully. <br />
                          Enjoy our services to play unlimited games.
                        </p>
                        <a href="/" className="btn btn-primary">
                          Home
                        </a>
                        <br /> <br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </Layout>
      </div>
    );
  }
}

export default SubscriptionSuccessful;
