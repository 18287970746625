import React, { Component } from "react";
import { gamesApi } from "../api/apiUrl";
import Layout from "./Layout";

export default class Category extends Component {
  state = { category: [] };
  componentDidMount() {
    console.log(this.props);
    gamesApi
      .get("/GetGamesBycategory", {
        params: {
          category: this.props.match.params.category
        }
      })
      .then(response => this.setState({ category: response.data }));
  }
  //render() {
  gameList() {
    return this.state.category.map(category => {
      return (
        <div className="col-4 col-md-2 pl-0 pr-1">
          <div className="card">
            <a href={"/gameDetails/" + category.GameID}>
              <img
                className="card-img-top"
                src={category.GameLogo}
                alt="Game 1"
              />
            </a>
            <div className="card-body">
              <p className="game-title">
                <a href={"/gameDetails/" + category.GameID}>
                  {category.GameTitle}r
                </a>
              </p>
            </div>
            <div className="card-footer">
              <a className="game-cat">{category.CategoryName}</a>
            </div>
          </div>
        </div>
      );
    });
  }
  render() {
    return (
      <Layout>
        <section className="main-content pt-nav">
          <div className="container">
            <div className="game-cat-section">
              <div className="container   pl-2 pr-2">
                <div className="d-flex flex-column pb-2">
                  <div className="section-header up-margin">
                    {this.state.category.CategoryName}
                  </div>
                  {/* <div className="sub-header">
                    <span>Showing 12 from 96 Games</span>
                  </div> */}
                </div>
                <div className="game-group row m-0">{this.gameList()}</div>
                {/* <div className="d-flex flex-column pt-4 pb-4">
                  <div className="sub-header">
                    <div className="select-dropdown float-left">
                      <select>
                        <option value="1">Show 12 Games</option>
                        <option value="2">Show 18 Games</option>
                      </select>
                    </div>
                    <div className="float-right">
                      <nav>
                        <ul className="pagination">
                          <li className="page-item">
                            <a className="page-link" href="#">
                              Prev
                            </a>
                          </li>
                          <li className="page-item">
                            <a className="page-link" href="#">
                              1
                            </a>
                          </li>
                          <li className="page-item">
                            <a className="page-link" href="#">
                              2
                            </a>
                          </li>
                          <li className="page-item">
                            <a className="page-link" href="#">
                              3
                            </a>
                          </li>
                          <li className="page-item">
                            <a className="page-link" href="#">
                              Next
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
